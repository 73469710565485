import { toHttps } from './util'

export class Song {
  constructor({ id, name, artist, album, image, duration, url }) {
    this.id = id
    this.name = name
    this.artist = artist
    this.album = album
    this.image = image
    this.duration = duration
    this.url = url
  }
}

export function createPlayList(music) {
  return new Song({
    id: music.id,
    name: music.name,
    artist: music.artist,
    album: music.album,
    image: toHttps(music.pic_url) || null,
    duration: music.duration / 1000,
    url: music.mp3
  })
}

export function createTopList(music) {
  return new Song({
    id: music.id,
    name: music.name,
    artist: music.artist,
    album: music.album,
    image: toHttps(music.pic_url) || null,
    duration: music.duration / 1000,
    url: music.mp3
  })
}

// 歌曲数据格式化
const formatSongs = function formatPlayList(list) {
  let Songs = []
  list.forEach(item => {
    const musicData = item
    if (musicData.id) {
      Songs.push(createPlayList(musicData))
    }
  })
  return Songs
}

export const formatTopSongs = function formatTopList(list) {
  let Songs = []
  list.forEach(item => {
    const musicData = item
    if (musicData.id) {
      Songs.push(createTopList(musicData))
    }
  })
  return Songs
}

export default formatSongs
